<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="add-btn" @click="createItem">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row>

    <div id="banner">
      <v-container class="secondary mt-5">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="banners.items"
              hide-default-footer
              :items-per-page="itemsPerPage"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title">Баннеры </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialogCreate" max-width="733px">
                    <v-card>
                      <v-card-title class="modal-title">
                        Баннер
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <div class="py-0 px-0">
                          <div class="col-content px-0 py-0 pb-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Название</p>
                            </v-col>

                            <v-col cols="9" class="col-paddings-r-l px-0 py-0">
                              <textarea
                                class="col-description textarea-pt"
                                v-model="bannerName"
                                ref="bannerNameTextarea"
                                :placeholder="
                                  isbannerNameFocused ? '' : textareaPlaceholder
                                "
                                @focus="isbannerNameFocused = true"
                                @blur="isbannerNameFocused = false"
                              ></textarea>
                            </v-col>
                          </div>
                        </div>
                        <div v-if="isEditing" class="horizontal-line"></div>
                        <div class="py-0 px-0">
                          <div
                            class="col-content px-0 py-0"
                            style="height: 63px"
                            v-if="isEditing"
                          >
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Статус</p>
                            </v-col>

                            <v-col cols="9" class="col-paddings-r-l px-0 py-0">
                              <v-select
                                class="textarea-pt"
                                v-model="selectedOptionState"
                                :items="options"
                                item-value="value"
                                item-text="text"
                                variant="underlined"
                              >
                              </v-select>
                            </v-col>
                          </div>
                        </div>
                        <div v-if="isEditing" class="horizontal-line"></div>
                        <v-col
                          cols="12"
                          class="photo-description px-0"
                          v-if="isEditing"
                        >
                          <div class="photo-container">
                            <div class="title-desc">
                              <p class="information-text-1">
                                Добавить картинку
                              </p>
                              <span
                                v-if="uploadedImageLogo"
                                class="information-text-2"
                                >1920рх* 831рх
                              </span>
                              <p
                                v-if="uploadedImageLogo"
                                class="information-text-3"
                              >
                                1mb
                              </p>
                            </div>
                            <div class="img-upload-container">
                              <div
                                v-if="uploadedImageLogo"
                                class="uploaded-image-container"
                              >
                                <img
                                  :src="uploadedImageLogo"
                                  alt="Uploaded Image"
                                  class="uploaded-image-logo"
                                />
                              </div>
                            </div>
                            <div class="img-add-btn-container">
                              <div class="img-container-flex">
                                <input
                                  type="file"
                                  accept="image/*"
                                  id="img-input-1"
                                  @change="uploadImageLogo"
                                  style="display: none"
                                />
                                <div class="btn-photo">
                                  <label
                                    for="img-input-1"
                                    class="custom-file-upload"
                                  >
                                    <img
                                      src="../assets/img/input-logo.svg"
                                      alt=""
                                    />
                                  </label>
                                  <label
                                    v-if="uploadedImageLogo"
                                    for="img-input-1"
                                    class="custom-file-upload"
                                  >
                                    <img
                                      @click="deleteImage"
                                      src="../assets/img/photo-delete.svg"
                                      alt=""
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                      </v-card-text>
                      <div class="btn-container px-6">
                        <div
                          v-if="isEditing"
                          :class="
                            currentState === 'Active'
                              ? 'save-btn-second'
                              : 'delete-btn'
                          "
                          text
                          @click="setBannerState"
                        >
                          {{
                            currentState === "Active"
                              ? "Активировать"
                              : "Деактивировать"
                          }}
                        </div>

                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div class="save-btn" text @click="handleButtonClick">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="717px">
                    <v-card>
                      <v-card-title class="warning-title" style=""
                        >{{
                          currentState === "Active"
                            ? "Вы точно хотите активировать данный баннер?"
                            : "Вы точно хотите деактивировать данный банер?"
                        }}
                        <v-btn icon @click="closeDelete">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>

                      <v-card-actions class="warning-margin">
                        <div class="cancel-btn" text @click="closeDelete">
                          Отмена
                        </div>
                        <div
                          :class="
                            currentState === 'Active'
                              ? 'save-btn-paswd'
                              : 'del-btn'
                          "
                          text
                          @click="setBannerState"
                        >
                          {{
                            currentState === "Active"
                              ? "Активировать"
                              : "Деактивировать"
                          }}
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-snackbar
                  v-model="snackbar"
                  :timeout="3000"
                  class="btn-contaiener"
                >
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div
                      class="snackbar-btn"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Закрыть
                    </div>
                  </template>
                </v-snackbar>
              </template>
              <template v-slot:item="{ item, index }">
                <tr style="cursor: pointer">
                  <td @click="getBanner(item)">
                    {{ item.name || " " }}
                  </td>

                  <td @click="getBanner(item)">
                    {{ item.createdOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getBanner(item)">
                    {{ item.modifiedOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="getBanner(item)">
                    {{ item.createdBy.fullName }}
                    <!-- <span v-for="(localization, index) in item.localizations" :key="index">
                        {{ localization.locale }}: {{ localization.name }}<br>
                    </span> -->
                  </td>
                  <td @click="getBanner(item)">
                    {{ getStatusTranslation(item.stateCode) }}
                  </td>
                  <td style="text-align: right">
                    <v-icon medium @click="toggleActionMenu(index)"
                      >mdi-dots-vertical</v-icon
                    >
                    <div
                      class="ActionMenu"
                      v-if="showActionMenu[index]"
                      ref="ActionMenu"
                    >
                      <div @click="getBanner(item)" class="ActionMenu-item-1">
                        Редактировать
                      </div>
                      <div class="divider-modal"></div>
                      <div @click="deleteItem(item)" class="ActionMenu-item-2">
                        {{
                          item.stateCode === "Active"
                            ? "Деактивировать"
                            : "Активировать"
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
export default {
  directives: { mask },
  name: "BannerPage",

  data: () => ({
    itemsPerPage: 10000,
    showActionMenu: [],
    isHovered: false,
    uploadedImageLogo: "",
    isHoveredTogo: false,
    showInputMenu: false,
    selectedOption: "",
    isPlaceholderHidden: false,
    isbannerNameFocused: false,
    selectedRoles: null,
    bannerName: "",
    dialogDelete: false,
    dialogCreate: false,
    snackbar: false,
    currentState: "",
    message: "",
    statusTranslations: {
      Active: "Активный",
      Inactive: "Неактивный",
    },
    newBanner: {},
    banner: {},
    selectedType: null,
    headers: [
      {
        text: "Название",
        align: "start",
        value: "name",
      },
      { text: "Дата создания", value: "createdOn" },
      { text: "Дата изменения", value: "modifiedOn" },
      { text: "Пользователь", value: "createdBy.fullName" },
      { text: "Статус", value: "stateCode" },
      { text: "Действия", align: "end", value: "actions", sortable: false },
    ],
    banners: [],
    profiles: [],
    isEditing: false,
    currentCategory: "",
    selectedOptionState: "",
    options: [
      {
        value: "Active",
        text: "Активен",
      },
      {
        value: "Inactive",
        text: "Неактивен",
      },
    ],
    uploadImageBanner: "",
    currentImageId: "",
    isImageChanged: false,
  }),

  created() {
    this.initialize();
    // this.profileInfo();
    document.addEventListener("click", this.closeActionMenu);
  },
  computed: {
    filteredStaffs() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        console.log(this.selectedType);
        return this.staffs.items
          .filter
          // (item) => item.rolesTest === this.selectedType
          ();
      } else {
        return this.staffs.items;
      }
    },

    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },

  mounted() {
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideTogo);
    document.addEventListener("click", this.closeActionMenu);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("click", this.closeActionMenu);
  },

  methods: {
    deleteImage() {
      this.uploadedImageLogo = null; // Сбросить выбранное изображение
      const fileInput = document.getElementById("img-input-1");
      fileInput.value = ""; // Очистить значение input type="file"
    },
    uploadImageLogo(event) {
      let file = event.target.files[0];

      // Проверяем, изменилось ли изображение
      if (file !== this.selectedImage) {
        this.isImageChanged = true;
        this.selectedImage = file;

        if (file) {
          this.uploadedImageLogo = URL.createObjectURL(file);
          console.log(this.uploadedImageLogo);
          this.uploadImageBanner = file;
        } else {
          this.uploadedImageLogo = null;
          this.uploadImageBanner = null;
        }
      }
    },
    // selectedOptionState(option) {
    //   this.selectedOptionState = option;
    // },
    getStatusTranslation(status) {
      return this.statusTranslations[status] || status;
    },
    handleClickOutsideActionMenu(event) {
      const ActionButton = this.$refs.ActionButton;
      const ActionMenu = this.$refs.ActionMenu;

      if (
        ActionButton &&
        ActionMenu &&
        !ActionButton.contains(event.target) &&
        !ActionMenu.contains(event.target)
      ) {
        this.uploadImageLogo = "";
        this.uploadImageBanner = "";
        this.showActionMenu = false;
      }
    },
    toggleActionMenu(index) {
      // Закрыть все другие меню
      for (let i = 0; i < this.showActionMenu.length; i++) {
        if (i !== index) {
          this.$set(this.showActionMenu, i, false);
        }
      }
      // Переключить меню для выбранного элемента
      this.$set(this.showActionMenu, index, !this.showActionMenu[index]);

      // Добавляем обработчик события на документе для закрытия меню при клике вне меню
      if (this.showActionMenu[index]) {
        document.addEventListener("click", this.closeActionMenu);
      } else {
        document.removeEventListener("click", this.closeActionMenu);
      }
    },
    closeActionMenu(event, index) {
      // Проверяем, был ли клик вне меню
      if (
        this.$refs.ActionMenu &&
        !this.$refs.ActionMenu.contains(event.target)
      ) {
        // Закрываем меню
        this.showActionMenu[index] = false;
        document.removeEventListener("click", (event) =>
          this.closeActionMenu(event, index)
        );
      }
    },
    getEnglishRoleTranslation(role) {
      for (const key in this.roleTranslations) {
        if (Object.prototype.hasOwnProperty.call(this.roleTranslations, key)) {
          if (this.roleTranslations[key] === role) {
            return key;
          }
        }
      }
      return role;
    },
    hidePlaceholder(inputName) {
      if (inputName === "categoryName") {
        this.iscategoryNameFocused = true;
      } else if (inputName === "categoryNameEn") {
        this.iscategoryNameEnFocused = true;
      }
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      // this.activeTextarea = null;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    closeDialog() {
      this.dialogCreate = false;
      this.bannerName = "";
      this.selectedOptionState = "";
      this.uploadedImageLogo = "";
    },
    handleButtonClick() {
      if (this.isEditing) {
        // Выполните метод для редактирования
        const isImageUpdated = this.isImageChanged;

        // Если изображение изменено, вызываем соответствующий метод
        if (isImageUpdated) {
          if (this.banner.images.length) {
            this.updateBannerImage();
          } else {
            this.createNewBannerImage();
          }
        }
        this.editBanner();
      } else {
        // Выполните метод для создания нового приложения
        this.createNewBanner();
      }
    },
    initialize() {
      this.$http
        .put("/api/Banner/PagedList", {
          pageNumber: this.currentPage,
          pageSize: 100,
          //   filters: [
          //     {
          //       property: "RestaurantId",
          //       operator: "eq",
          //       value: this.user.currentRest.restaurant.id,
          //     },
          //   ],
          sorting: {
            property: "CreatedOn",
            isDescending: true
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.banners = response.data;
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
          console.log(this.totalItems);
        })
        .catch((error) => (this.error = error));
    },
    createNewBannerImage() {
      const formData = new FormData();
      formData.append("file", this.uploadImageBanner);
      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
        params: {
          entityName: "bannerbase",
          entityId: this.currentCategory,
        },
      };

      this.$http
        .post("/api/Image", formData, config)
        .then(() => {
          this.snackbar = true;
          this.message = "Картинка успешно добавленна";
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка добавления картинки";
        });
    },
    updateBannerImage() {
      const formData = new FormData();
      formData.append("file", this.uploadImageBanner);
      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
        params: {
          entityName: "bannerbase",
          entityId: this.currentCategory,
        },
      };

      this.$http
        .put(`/api/Image/${this.currentImageId}`, formData, config)
        .then((response) => {
          this.snackbar = true;
          this.message = "Картинка успешно изменена";
          const timestamp = new Date().getTime();
          this.uploadedImageLogo =
            process.env.VUE_APP_API_HOST +
            response.data.imagePath +
            `?t=${timestamp}`;
          console.log(this.uploadedImageLogo);
        })
        .catch((error) => {
          this.error = error;
          this.snackbar = true;
          this.message = "Ошибка изменения картинки";
        });
    },
    deleteItem(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.editedIndex = this.banners.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";
      this.dialogDelete = true;
      console.log(this.currentState);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
      this.isEditing = false;
      this.bannerName = "";
      // this.getEmployee();
    },

    closeCreate() {
      this.dialogCreate = false;
      this.bannerName = "";
      this.selectedOptionState = "";
      this.uploadedImageLogo = "";
      this.isEditing = false;
    },

    createNewBanner() {
      //   this.newEmp.employeeRole = this.getEnglishRoleTranslation(
      //     this.selectedOption
      //   );
      (this.newBanner.name = this.bannerName),
        // (this.newProduct.uom = this.selectedOptionState),
        this.$http
          .post("/api/Banner", this.newBanner)
          .then(() => {
            this.initialize();
            this.dialogCreate = false;
            this.newBanner = {
              name: "",
            };
            this.snackbar = true;
            this.message = "Баннер успешно добавлен";
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
            this.message = "Ошибка добавления баннера";
          });
    },
    getBanner(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.createItem();
      this.isEditing = true;
      this.editedIndex = this.banners.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";

      this.$http
        .get(`/api/Banner/${item.id}`)
        .then((response) => {
          this.banner = response.data;
          this.bannerName = this.banner.name;
          this.selectedOptionState = this.banner.stateCode;
          if (this.banner.images.length && this.banner.images[0]) {
            const randomParam = Math.random();
            this.uploadedImageLogo =
              process.env.VUE_APP_API_HOST +
              this.banner.images[0].imagePath +
              `?random=${randomParam}`;
            this.currentImageId = this.banner.images[0].id;
            console.log(this.uploadedImageLogo);
          } else {
            this.uploadedImageLogo = "";
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    editBanner() {
      this.newBanner = {};
      this.newBanner.name = this.bannerName;
      this.newBanner.id = this.currentCategory;
      if (this.newBanner.stateCode !== this.currentState) {
        this.newBanner.stateCode = this.currentState;
        this.setBannerState();  // Вызов метода setBannerState, если stateCode изменился
      }
      this.$http
        .put(`/api/Banner`, this.newBanner, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.initialize();
          this.dialogCreate = false;
          this.banner = response.data;
          this.newBanner = {
            id: "",
            name: "",
            stateCode: "",
          };
          this.snackbar = true;
          this.message = "Баннер успешно изменен";
        })
        .catch((error) => {
          console.log("ТАКАЯ ОШИБКА" + error);
          this.snackbar = true;
          this.message = "Ошибка редактирования баннера";
        });
    },
    setBannerState() {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.$http
        .put(`/api/Banner/SetState/${this.currentCategory}`, null, {
          params: {
            stateCode: this.currentState,
          },
        })
        .then((response) => {
          this.curUser = response.data;
          this.dialogCreate = false;
          this.initialize();
          this.closeDelete();
          this.snackbar = true;
          this.message = "Статус усмешно изменен";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.message = "Ошибка выполнения";
        });
    },
    // editItem(item) {
    //   this.$router.push({ path: `/admin/application/${item.id}` });
    // },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },

    handleClickOutside(event) {
      const inputButton = this.$refs.inputButton;
      const inputMenu = this.$refs.inputMenu;

      if (
        inputButton &&
        inputMenu &&
        !inputButton.contains(event.target) &&
        !inputMenu.contains(event.target)
      ) {
        this.uploadImageLogo = "";
        this.uploadImageBanner = "";
        this.showInputMenu = false;
      }
    },

    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
  },

  watch: {
    bannerName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("bannerNameTextarea");
      });
    },

    categoryNameEn() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("categoryNameEnTextarea");
      });
    },
  },
};
</script>

<style scoped>
.del-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #b80900);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  /* margin-left: 30px; */
}
.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
}
.warning-margin {
  margin-top: 32px;
  margin-bottom: 10px;
}
.warning-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  justify-content: space-between;
  padding: 31px 14px 10px 31px !important;
}
.save-btn-paswd {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  /* margin-left: 30px; */
}
::v-deep .v-toolbar__content {
  align-items: center !important;
}
.photo-container {
  display: flex;
  justify-content: space-between;
}
.custom-file-upload {
  cursor: pointer;
}
.btn-photo {
  display: flex;
  flex-direction: column;
}
.uploaded-image-logo {
  max-width: 450px; /* Adjust this value as needed */
  max-height: 195px; /* Adjust this value as needed */
}
.uploaded-image-container {
  max-width: 450px;
  max-height: 195px;
}
.img-container-flex {
  display: flex;
  justify-content: space-between;
}
.information-text-3 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.information-text-2 {
  color: #9e9e9e;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.information-text-1 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.photo-description {
  display: flex;
  flex-direction: column;
}
::v-deep .v-select__selection--comma {
  margin: 7px 4px 7px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  bottom: -1px;
  content: none !important;
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
::v-deep
  .theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ):hover {
  background: #f2f2f680;
}

.ActionMenu {
  position: absolute;
  /* bottom: -110px;
  left: -180px; */
  width: 156px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  right: 2%;
}
.ActionMenu-item-1:hover,
.ActionMenu-item-2:hover {
  background-color: rgba(
    242,
    242,
    246,
    0.5
  ); /* изменяем цвет текста при наведении мыши */
}

.ActionMenu-item-1 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-top-left-radius: 10px; /* Закругляем нижний левый угол */
  border-top-right-radius: 10px; /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}
.ActionMenu-item-2 {
  border-bottom-left-radius: 10px; /* Закругляем нижний левый угол */
  border-bottom-right-radius: 10px; /* Закругляем нижний правый угол */
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-align: start;
  cursor: pointer;
}
/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
  color: #fff; /* White text color */
}

::v-deep .v-data-table-header__icon.mdi-arrow-up,
::v-deep .v-data-table-header__icon.mdi-arrow-down {
  display: inline-block;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-up */
  font-family: "Material Design Icons";
}

/* Значок сортировки по убыванию */
::v-deep .v-data-table-header__icon.mdi-arrow-up.rev::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down.rev::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-down */
  font-family: "Material Design Icons";
}
.custom-snackbar .v-btn--text.v-btn--success:hover {
  background-color: #357a38; /* Darker green background on hover */
}
.role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}
.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}
.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0; /* Пример: изменение цвета фона при наведении */
  color: #333; /* Пример: изменение цвета текста при наведении */
  cursor: pointer; /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}
.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}
.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
  width: 100%;
}
.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%; /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}

.input-menu {
  position: absolute;
  bottom: 32%;
  left: 30.7%;
  width: 347px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}
.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  /* margin-left: 15px; */
}
.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-left: 5px;
}
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 46px;
  align-items: center;
}
.red-dot-margin-2 {
  margin-left: -17px;
}
.red-dot-margin-1 {
  text-align: center;
  margin-left: -17px;
}
.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}
.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 28px;
  margin-right: 28px;
}
.delete-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.save-btn-second {
  color: #00b800;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}
.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.text-limit {
  width: 30ch; /* Отображение примерно 10 символов */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Gray-1, #333);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
}
.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px; /* Толщина линии */
  background: #848484; /* Цвет линии (в данном случае черный) */
}
.custom-select {
  position: relative;
  width: 350px;
  cursor: pointer;
  /* margin-left: 9px; */
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 30px;
  /* transform: translateY(-50%); */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333; /* Цвет стрелочки */
}
.col-description,
:focus {
  outline: none; /* Убираем внешний контур (outline) при фокусе */
  border: none; /* Убираем границу (border) при фокусе */
  box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}
.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
  /* height: 200px; */
  display: flex;
  align-items: center;
}
.textarea-pt {
  padding-top: 19px;
}

.col-content {
  display: flex;
  align-items: center;
  /* height: 63px; */
}
.role-margin {
  margin-right: 37px;
}
.role-margin-1 {
  margin-right: 30px;
}

.col-content-2 {
  display: flex;
  align-items: center;
  width: fit-content;
}
.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  width: 100%;
  padding-left: 7px;
  min-height: 20px; /* Минимальная высота для textarea */
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}
.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}
.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}
.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}
.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black; /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}
.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  /* margin-left: 37px; */
  cursor: pointer;
}
.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}
.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-pluss-btn {
  margin-left: 10px;
}
.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.search-component {
  /* max-width: 606px; */
  width: 30vw;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.search-component__input {
  border-radius: 100px;
  border: 1px solid var(--gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 30%;
  right: 55px;
}
#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff);
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}
::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
::v-deep .v-data-table__wrapper > table > thead > tr > th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid #848484;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
